import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../../request';
import {InPut, InGet, GetCinterest, CommonDataCinterest, InPutCommonData} from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../../utils';

@Injectable()
export class ApiSiteCinterestService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Список
     *
     * @param {InGet} obj
     * @return {Promise<Get>}
     */
    get(obj: InGet): Promise<GetCinterest> {
        return this._request
            .query('arm/v1/content-site/cinterest', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    getCommonData(): Promise<CommonDataCinterest> {
        return this._request
            .query('arm/v1/content-site/cinterest/common')
            .then((res: Response) => res.json);
    }

    /**
     * Обновление
     * @param obj
     * @param files
     * @returns {Promise<{result: boolean}|TResult2|TResult1>}
     */
    put(obj: InPut, files: File[] = []): Promise<any> {
        return this._request
            .update('arm/v1/content-site/cinterest', obj, files)
            .then((res: Response) => res.json)
    }

    putCommonData(obj: InPutCommonData): Promise<any> {
        return this._request
            .update('arm/v1/content-site/cinterest/common', obj)
            .then((res: Response) => res.json)
    }
}

