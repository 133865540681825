import { Injectable } from '@angular/core';
import { IMenu } from './interface';
import { forEach, clone } from 'lodash';

@Injectable()
export class AsideNavService {

    constructor() {
    }

    /**
     * Список менюшки
     *
     * @returns {any[]}
     */
    static getListMenu(permission: string[]): any[] {
        const menu = [{
            title: 'Рассылки',
            link: false,
            icon: 'flaticon-alarm-1',
            type: 'notifications',
            children: [{
                title: 'Новая рассылка',
                link: '/notifications/new'
            }, {
                title: 'Архив рассылок',
                link: '/notifications/archive'
            }]
        }, {
            title: 'Как заказать',
            link: false,
            icon: 'flaticon-info',
            type: 'instruction',
            children: [{
                title: 'Список',
                link: '/instruction/archive'
            }]
        }, {
            title: 'Отзывы',
            link: false,
            icon: 'flaticon-file',
            type: 'feedback',
            children: [{
                title: 'Товары',
                children: [{
                    title: 'Ждут модерации',
                    link: '/feedback/items/moderation'
                }, {
                    title: 'Список',
                    link: '/feedback/items/list'
                }]
            }, {
                title: 'Сервис',
                children: [{
                    title: 'Ждут модерации',
                    link: '/feedback/service/moderation'
                }, {
                    title: 'Список',
                    link: '/feedback/service/list'
                }]
            }, {
                title: 'Описание',
                link: '/feedback/items/description'
            }]
        }, {
            title: 'Статистика',
            link: false,
            icon: 'flaticon-graphic-2',
            type: 'stats',
            children: [{
                title: 'Брошенная корзина',
                link: '/cart/stats'
            }, {
                title: 'Длительность сессий',
                link: '/involvement/sessions'
            }, {
                title: 'Заказы в МП',
                link: '/commerce/orders'
            }, {
                title: 'Воронка по заказам',
                link: '/commerce/events'
            }],
        }, {
            title: 'Пользователи',
            link: false,
            icon: 'flaticon-users',
            type: 'users',
            children: [{
                title: 'Список',
                link: '/users/list'
            }, {
                title: 'Тестирование',
                link: '/users/test'
            }, {
                title: 'Заблокированные карты',
                link: '/users/cards-banned'
            }],
        }, {
            title: 'Промокоды',
            link: false,
            icon: 'flaticon-gift',
            type: 'promocodes',
            children: [{
                title: 'Генерация акционных промокодов',
                link: '/promocodes/generation/list'
            }, {
                title: 'Промокоды партнеров',
                link: '/promocodes/partners/list'
            }, {
                title: 'Реферальные промокоды',
                link: '/promocodes/referral/list'
            }],
        }, {
            title: 'Вопросы/Ответы',
            link: false,
            icon: 'flaticon-info',
            type: 'questions',
            children: [{
                title: 'Список категорий',
                link: '/questions/category/list'
            }, {
                title: 'Список вопросов',
                link: '/questions/list'
            }]
        }, {
            title: 'Выгрузки',
            link: false,
            icon: 'flaticon-file',
            type: 'exch',
            children: [{
                title: 'Яндекс.Маркет',
                link: '/exch/yandex'
            }, {
                title: 'Товары не в наличии',
                link: '/exch/items'
            }, {
                title: 'Стоимость доставки такси',
                link: '/exch/delivery'
            }]
        }, {
            title: 'Функционал',
            link: false,
            icon: 'flaticon-rocket',
            type: 'functions',
            children: [{
                title: 'Параметры',
                children: [{
                    title: 'Общие',
                    link: '/functions/params/common'
                }, {
                    title: 'Доставка',
                    link: '/functions/params/delivery'
                }, {
                    title: 'Самовывоз',
                    link: '/functions/params/pickup'
                }, {
                    title: 'ЭС',
                    link: '/functions/params/certs'
                }]
            }, {
                title: 'Ограниченное тестирование',
                link: '/functions/test'
            }, {
                title: 'Генерация ссылки для писем',
                link: '/functions/items-link'
            }, {
                title: 'Баннер МП после заказа',
                link: '/functions/order-banner'
            }]
        }, {
            title: 'Доступы',
            link: false,
            icon: 'flaticon-lock',
            type: 'permission',
            children: [{
                title: 'Список',
                link: '/permission/list'
            }]
        }, {
            title: 'Электронные сертификаты',
            link: false,
            icon: 'flaticon-internet',
            type: 'certs',
            children: [{
                title: 'Список',
                link: '/certs/list'
            }, {
                title: 'Заказы',
                link: '/certs/orders'
            }, {
                title: 'Аннулирование',
                link: '/certs/cancel'
            }]
        }, {
            title: 'ЭС для ЮЛ',
            link: false,
            icon: 'flaticon-internet',
            type: 'certs-legal',
            children: [{
                title: 'Список',
                link: '/certs-legal/list'
            }, {
                title: 'Заказы',
                link: '/certs-legal/orders'
            },]
        }, {
            title: 'Рекламные подборки',
            link: false,
            icon: 'flaticon-notes',
            type: 'reclame',
            children: [{
                title: 'Список',
                link: '/reclame/list'
            }]
        }, {
            title: 'Ошибки и предложения',
            link: false,
            icon: 'flaticon-notes',
            type: 'appeals',
            children: [{
                title: 'Список',
                link: '/appeals/list'
            }]
        }, {
            title: 'Истории',
            link: false,
            icon: 'flaticon-notes',
            type: 'stories',
            children: [{
                title: 'Список',
                link: '/stories/list'
            }]
        }, {
            title: 'Контент сайта и МП',
            link: false,
            icon: 'flaticon-file',
            type: 'content-site',
            children: [{
                title: 'Баннеры на главной',
                link: '/content-site/banners-main/list'
            }, {
                title: 'Боковой баннер',
                link: '/content-site/banners-right/list'
            }, {
                title: 'Нижний баннер',
                link: '/content-site/banners-bottom/list'
            }, {
                title: 'Акции',
                link: '/content-site/specials/list'
            }, {
                title: 'Новости',
                link: '/content-site/news/list'
            }, {
                title: 'Статьи',
                link: '/content-site/articles/list'
            }, {
                title: 'Проекты',
                link: '/content-site/projects/list'
            }, {
                title: 'Блок Аптеки Планета Здоровья',
                link: '/content-site/advantages/list'
            }, {
                title: 'Вакансии',
                link: '/content-site/work/list'
            }, {
                title: 'Блок "Акцент"',
                link: '/content-site/accent'
            }, {
                title: 'Баннеры на главной МП',
                link: '/content-site/banners-mobile/list'
            }, {
                title: 'Блок "Интересные категории"',
                link: '/content-site/cinterest/list'
            }, {
                title: 'Подсказки под строкой поиска',
                link: '/content-site/search-hints/list'
            }]
        }, {
            title: 'Восстановления сертификата',
            link: false,
            icon: 'flaticon-internet',
            type: 'recovery-cert',
            children: [{
                title: 'Восстановление',
                link: '/recovery-cert/recovery'
            }, {
                title: 'Выгрузка',
                link: '/recovery-cert/load'
            }]
        }, {
            title: 'Заказы',
            link: false,
            icon: 'flaticon-internet',
            type: 'orders',
            children: [{
                title: 'Список',
                link: '/orders/list'
            }, {
                title: 'Расчет оценки заказов',
                link: '/orders/rate'
            }, {
                title: 'Расчет вариантов ответа',
                link: '/orders/topics'
            }, {
                title: 'Выгрузка комментариев',
                link: '/orders/comment'
            }]
        }, {
            title: 'Социальные сети',
            link: false,
            icon: 'flaticon-notes',
            type: 'socials',
            children: [{
                title: 'Список',
                link: '/socials/list'
            }]
        }, {
            title: 'Соглашения и программы',
            link: false,
            icon: 'flaticon-file',
            type: 'programs',
            children: [{
                title: 'Список',
                link: '/programs/list'
            }]
        }, {
            title: 'Предложения',
            link: false,
            icon: 'flaticon-file',
            type: 'offers',
            children: [{
                title: 'Специальные предложения',
                link: '/offers/specials-tabs/list'
            }, {
                title: 'Сезонные предложения',
                link: '/offers/seasonal/list'
            }]
        }];

        // Проверка
        let tmpMenu = [];
        forEach(menu, (v) => {
            let tmp;
            const f = permission.indexOf(v.type);
            // 1 уровень
            if (f !== -1) {
                tmp = clone(v);
            }
            if (tmp) {
                tmpMenu.push(tmp);
            }
        });

        return tmpMenu;
    }
}
